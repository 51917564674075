$(function () {
    $('[data-moveTo]').on('click', function () {
        var speed = 500;
        var href = $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        
        if ($(window).width() < 1024){
            var positionSp = target.offset().top - $('header').height() + $('.p-gnav').outerHeight();
            $('html, body').animate({
                scrollTop: positionSp
            }, speed, 'swing');
            
            return false;
            
        } else {
            var position = target.offset().top - $('header').height();
            $('html, body').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        }
        
    }); 

    
});

$(window).on('load', function() {
    var target = location.hash;
    if (target) {
      let position = $(target).offset().top - $('header').height();
      $('html, body').animate({ scrollTop: position }, 0);
    }
});